<template>
  <div class="login_container">
    <div class="login_header">
      <div class="header_img">
        <img :src="require('@/assets/images/header.png')" />
      </div>
    </div>
    <div class="login_body">
      <!-- 登录信息 -->
      <div class="login_information_block">
        <div class="login_information_input">
          <div class="login_information_input_key">
            <el-icon>
              <PhoneFilled />
            </el-icon>
          </div>
          <div class="login_line"></div>
          <div class="login_information_input_value global_text_input">
            <el-input v-model.number="data.phone" placeholder="请输入手机号" />
          </div>
        </div>
        <div class="login_information_input">
          <div class="login_information_input_key">
            <el-icon>
              <Lock />
            </el-icon>
          </div>
          <div class="login_line"></div>
          <div class="login_information_input_value global_text_input">
            <div class="sign_inputcode">
              <el-input
                v-model="data.code"
                @input="changeCode"
                placeholder="请输入验证码"
              />
            </div>
            <div class="sign_getcode" @click="sendCode">
              {{ timeItem.text || "获取验证码" }}
            </div>
          </div>
        </div>
      </div>
      <div class="login_button">
        <el-button type="primary" size="mini" @click="doLogin">登录</el-button>
      </div>
    </div>
    <opacityBg v-if="showSelectProject">
      <div
        class="meetingInfo_course_select_container_center"
        v-if="config.projects.length > 0 && config.projects.length <= 4"
      >
        <div class="meetingInfo_course_select" @click.stop="">
          <div
            class="meetingInfo_course_select_title global_primary_text_color"
          >
            请选择项目
          </div>
          <div
            class="meetingInfo_course_select_ul"
            v-for="(item, index) in config.projects"
            :key="index"
          >
            <el-radio
              class="meetingInfo_course_select_radio"
              @change="changeProject"
              v-model="projectId"
              :label="item.project"
              >&nbsp;</el-radio
            >
            <div
              class="meetingInfo_course_select_radio_label"
              @click="doSelectProject(item.id)"
            >
              {{ item.project }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="meetingInfo_course_select_container"
        v-if="config.projects.length > 4"
      >
        <itemScroll ref="selectScroll">
          <div class="meetingInfo_course_select" @click.stop="">
            <div
              class="meetingInfo_course_select_title global_primary_text_color"
            >
              项目列表
            </div>
            <div
              class="meetingInfo_course_select_ul"
              v-for="(item, index) in config.projects"
              :key="index"
            >
              <el-radio
                class="meetingInfo_course_select_radio"
                @change="changeProject"
                v-model="projectId"
                :label="item.project"
                >&nbsp;</el-radio
              >
              <div
                class="meetingInfo_course_select_radio_label"
                @click="doSelectProject(item.id)"
              >
                {{ item.project }}
              </div>
            </div>
          </div>
        </itemScroll>
      </div>
    </opacityBg>
  </div>
</template>
<script>
import { ElIcon, ElButton, ElInput, ElRadio } from "element-plus";
import { PhoneFilled, Lock } from "@element-plus/icons";
import opacityBg from "@/components/unit/opacityBg";
import itemScroll from "@/components/unit/itemScroll";
export default {
  name: "login",
  data() {
    return {
      data: {
        phone: "",
        code: "",
      },
      projectId: "",
      timeItem: {
        startTime: 0,
        timer: null,
        maxCount: 60,
        text: "",
        loading: false,
      },
      config: {
        projects: [
          // {
          //     project:"百研小安文献解读",
          // },
          // {
          //     project:"肺癌免疫治疗文献精读挑战赛",
          // },
        ],
      },
      loading: false,
      showSelectProject: false,
    };
  },
  created() {
    document.getElementsByTagName("title")[0].innerHTML = "登录";
    let data = this.$tools.getLocalCookie("adminLoginData") || null;
    if (data) this.data.phone = data.phone;
    this.timeItem.startTime = Number(this.$tools.getStorage("downStartTime"));
    this.checkTime();
  },
  mounted() {},
  components: {
    PhoneFilled,
    Lock,
    ElInput,
    ElButton,
    ElIcon,
    ElRadio,
    opacityBg,
    itemScroll,
  },
  methods: {
    checkTime() {
      if (this.timeItem.timer) {
        clearTimeout(this.timeItem.timer);
        this.timeItem.timer = null;
      }
      let curTime = Date.now();
      if (this.timeItem.startTime + this.timeItem.maxCount * 1000 > curTime) {
        this.timeItem.loading = true;
        this.timeItem.text = `${
          this.timeItem.maxCount -
          Math.floor((curTime - this.timeItem.startTime) / 1000)
        }s`;
        this.$tools.setStorage("downStartTime", this.timeItem.startTime);
        this.timeItem.timer = setTimeout(() => {
          this.checkTime();
        }, 1000);
      } else {
        this.timeItem.startTime = 0;
        (this.timeItem.text = ""), (this.timeItem.loading = false);
        this.$tools.setStorage("downStartTime", this.timeItem.startTime);
      }
    },
    changeCode() {
      this.data.code = this.data.code.substr(0, 5);
    },
    loadProject() {
      // this.showSelectProject =  true;
      let url = this.$tools.getURL(this.$urls.admin.myProject);
      this.$axios
        .get(url)
        .then((res) => {
          if (res.data.length == 1) {
            let item = res.data[0];
            this.doSelectProject(item.id);
            return;
          }
          this.config.projects = [...res.data].reverse();
          this.showSelectProject = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeProject() {
      this.showSelectProject = false;
    },
    doSelectProject(projectId) {
      this.$tools.setStorage("projectId", projectId, sessionStorage);
      this.showSelectProject = false;
      this.$router.push({
        path: "/doctorMeets",
      });
    },
    sendCode() {
      if (this.timeItem.loading) return;
      console.log(this.data.phone, !this.data.phone);
      if (!this.data.phone) {
        this.$tips.error({ text: "请输入手机号码" });
        return;
      }
      if (!/^1\d{10}$/g.test(this.data.phone)) {
        this.$tips.error({ text: "手机号码不正确,请检查" });
        return;
      }
      this.timeItem.startTime = Date.now();
      this.checkTime();
      let url = this.$tools.getURL(this.$urls.user.code, {
        phone: this.data.phone,
      });
      this.$axios
        .get(url)
        .then(() => {
          this.$tips.success({ text: "发送成功" });
        })
        .catch(() => {});
    },
    verifyData() {
      if (!this.data.phone) {
        this.$tips.error({ text: "请输入手机号码" });
        return false;
      }
      if (!/^1\d{10}$/g.test(this.data.phone)) {
        this.$tips.error({ text: "手机号码不正确,请检查" });
        return false;
      }
      if (!this.data.code) {
        this.$tips.error({ text: "请输入验证码" });
        return false;
      }
      return true;
    },
    doLogin() {
      if (this.loading || !this.verifyData()) return;
      this.loading = true;
      this.$axios
        .post(this.$urls.user.login, this.data)
        .then((res) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          this.$store.dispatch("setItem", { adminInfo: res.data });
          this.$tools.setLocalCookie("adminLoginData", this.data);

          let projectId = this.$tools.getStorage("projectId", sessionStorage);
          if (!projectId) {
            this.loadProject();
          } else {
            this.$router.push({
              path: "/doctorMeets",
              // query: {
              //   id: res.data.doctor_id,
              // },
            });
          }
        })
        .catch((err) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          console.log(err);
        });
    },
  },
};
</script>

<style>
.login_container {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 0 10vw;
}
.login_header {
  width: 100%;
  height: 53.2vw;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 19.2vw;
}
.header_img {
  width: 42vw;
  height: 13.33vw;
}
.header_img img {
  width: 100%;
  height: 100%;
}
.login_body {
  width: 100%;
}
.login_information_block {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 7.2vw;
}
.login_information_input {
  height: 13.4vw;
  /* height: 15vw; */
  display: flex;
  align-items: center;
  padding: 3.8vw 0 1.1vw 0;
  border-bottom: 1px #b8b8b8 solid;
}
.login_information_input_key {
  width: 10.8vw;
  height: 100%;
  /* background: #d6d6d6; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_information_input_key {
  font-size: 4vw;
}
.login_information_input_key svg,
.login_information_input_key .el-icon {
  height: 1.8em;
  width: 1.8em;
}
.login_information_input_value {
  /* height: 10vw; */
  flex: 1 0 0;
  display: flex;
  border: none;
}
.login_information_input_value input {
  border: none;
  font-size: 4vw;
}
.login_button {
  display: flex;
  justify-content: center;
}
.login_button .el-button {
  border: none;
  width: 60vw;
  height: 8.16vw;
  border-radius: 1vw;
  background: url("../../../assets/images/icon/button.png");
  background-size: 100%;
}
.login_button .el-button:hover {
  background: url("../../../assets/images/icon/button.png");
  background-size: 100%;
}
.login_line {
  height: 100%;
  border-right: 1px #b8b8b8 solid;
  /* margin-right: 2.8vw; */
}
.sign_inputcode {
  flex: 1 0 0;
}
.sign_getcode {
  height: 8.4vw;
  width: 22vw;
  background: #d6d6d6;
  border-radius: 1vw;
  /* font-weight: 400; */
  font-size: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.meetingInfo_course_select_container_center {
  width: 80%;
}
.meetingInfo_course_select_container {
  height: 80%;
  width: 80%;
  overflow: hidden;
}
.meetingInfo_course_select_container_center {
  width: 80%;
}
.meetingInfo_course_select_title {
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 700;
}
.meetingInfo_course_select {
  background: rgba(224, 224, 224, 0.9);
  width: 100%;
  padding: 2vw 4vw;
  border-radius: 2vw;
}
.meetingInfo_course_select_ul {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000;
  padding: 2vw;
}
.meetingInfo_course_select_ul:last-child {
  border: none;
}
.meetingInfo_course_select_ul .el-radio {
  margin-right: 0;
}
.meetingInfo_course_select_ul .el-radio__label {
  padding: 0;
  width: 0;
}
.meetingInfo_course_select_radio_label {
  word-break: break-all;
  margin-left: 2vw;
}
</style>
